import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<BreadcrumbItem[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  constructor() {}

  setBreadcrumbs(breadcrumbs: BreadcrumbItem[]) {
    this.breadcrumbsSubject.next(breadcrumbs);
  }
}

export interface BreadcrumbItem {
  title?: string;
  link: string;
  icon?: string;
  id: number;
}
